<template>
  <div :class="`section-title ${align} ${color}`">
    <div class="title-left">
      <div class="section-left_tag">
        <span></span><span></span><span></span>
      </div>
      <div slot="primary" class="title-left_primary" >
        <slot name="primary"></slot>
      </div>
      <div class="title-left_minor">
        <slot name="minor"></slot>
      </div>
    </div>
    <div class="title-right">
      <slot name="rightText"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sectionTitle',
  props: {
    color: {
      type: String,
      default: 'black' // black  white
    },
    align: {
      type: String,
      default: 'space-between' // space-between  center  left
    }
  }
}
</script>

<style scoped lang="less">
@import "~@/styles/mixin";
.section-title{
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0;
  &.black{
    .title-left{
      .title-left_primary{
        color: #002948;
      }
    }
  }
  &.white{
    .title-left{
      .title-left_primary{
        color: #F7FAFF;
      }
    }
  }
  &.space-between{
    justify-content: space-between;
    text-align: left;
  }
  &.center{
    justify-content: center;
    text-align: center;
  }
  .title-left{
    user-select: none;
    .section-left_tag{
      display: inline-flex;
      padding-bottom: 25px;
      span{
        background-color: @primary-color;
        display: block;
        height: 10px;
        border-radius: 2px;
        & + span{
          margin-left: 5px;
        }
        &:nth-child(1) {
          width: 40px;
        }
        &:nth-child(2) {
          width: 10px;
        }
        &:nth-child(3) {
          width: 6px;
        }
      }
    }
    .title-left_primary{
      font-weight: bold;
      font-size: 34px;
      color: #002948;
      line-height: 1;
    }
    .title-left_minor{
      line-height: 1;
      margin-top: 10px;
      color: #CCD4DA;
      font-size: 22px;
    }
  }
  .title-right{
    font-weight: 700;
    line-height: 1;
    font-size: 100px;
    user-select: none;
    color: rgba(204,212,218, .1);
  }
}
</style>
