<template>
  <div class="home">
    <div class="home-banner">
      <div class="home-banner-content">
        <el-carousel ref="carousel" trigger="click" height="480px" arrow="never">
          <el-carousel-item v-for="(item, index) in bannerData" :key="index">
            <div class="el-carousel-item_container"
                 :style="`background-color:${item.bgColor};`">
              <div
                class="el-carousel-item_content"
                v-if="item.url"
                :style="`background: url(${item.url}) no-repeat center center ;background-size: cover;`| isWebp(item.url)"
              ></div>
              <div class="el-carousel-item_content" v-else></div>
            </div>
          </el-carousel-item>
          <div
            @click="$refs.carousel.prev()"
            class="prev-btn"
          >
            <img src="./icon-arrow-right.png"/>
          </div>
          <div
            @click="$refs.carousel.next()"
            class="next-btn"
          >
            <img src="./icon-arrow-right.png"/>
          </div>
        </el-carousel>
      </div>
      <div class="home-banner-detail">
        <ul>
          <li :style="`background-image: url(${item.url});background-size: 68px 91px` | isWebp(item.url)"
              :key="index"
              v-for="(item, index) in bannerTipsData">
            <div class="home-banner-detail_title">{{item.title}}</div>
            <div class="home-banner-detail_content">{{item.content}}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="section-1">
      <sectionTitle align="center" color="black">
        <template #primary>产品服务</template>
        <template #minor>一站式人力资源服务平台</template>
      </sectionTitle>
      <div class="section-product section-common">
        <div class="product-nav">
          <div
            ref="productItem"
            class="nav-item"
            :class="{
              on: productIndex === index
            }"
            @mouseover="changeProductIndex(index)"
            v-for="(item, index) in product"
            :key="index"
          >
            {{ item.name }}
          </div>
          <div
            v-if="this.product[productIndex].style"
            class="nav-line"
            :style="this.product[productIndex].style"
          ></div>
        </div>
        <div class="product-content">
          <div class="product-content_left">
            <GImage :g-src="this.product[productIndex].icon"/>
          </div>
          <div class="product-content_right">
            <el-collapse
              :key="index"
              v-for="(item, index) in this.product[productIndex].list"
              v-model="productActiveName"
              accordion
            >
              <el-collapse-item
                v-custom-el-collapse
                :title="item.title"
                :name="index"
              >
                <div>{{ item.text }}</div>
              </el-collapse-item>
            </el-collapse>
            <div class="collapse-btn">
              <router-link
                tag="div"
                :to="this.product[productIndex].path"
                class="default-btn btn-FFDD00 btn-radius"
              >
                了解更多
              </router-link>
              <div
                class="default-btn btn-FFF btn-radius"
                @click="$linkInsidePages({path:'/login'})"
              >
                登录
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="$PRERENDER_INJECTED">
      <div class="section-2">
        <sectionTitle align="center" color="black">
          <template #primary>我们的优势</template>
          <template #minor>数字化专家团队为企业提供一体化用工服务</template>
        </sectionTitle>
        <div class="section-advantage section-common">
          <div class="advantage-content">
            <div
              class="advantage-list"
              :key="index"
              v-for="(item, index) in advantage"
            >
              <div class="list-icon">
                <img :src="item.icon" alt="">
              </div>
              <div class="list-title">
                {{ item.name }}
              </div>
              <div class="list-text">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-3">
        <sectionTitle align="center" color="black">
          <template #primary>合作伙伴</template>
          <template #minor>6000+行业领先企业之选</template>
        </sectionTitle>
        <div class="section-partner">
          <div
            v-row-scroll="logoFilesLength"
            ref="logoContainer"
            class="img-container"
          >
            <div class="img-list">
              <template v-for="(item, index) in logoFiles" >
                <img
                  :src="item"
                  alt=""
                  v-if="index < logoFilesLength / 3" :key="index">
              </template>
            </div>
            <div class="img-list" >
              <template v-for="(item, index) in logoFiles" >
                <img
                  :key="index"
                  :src="item"
                  alt=""
                  v-if="index >= logoFilesLength / 3 && index < logoFilesLength / 3 * 2">
              </template>
            </div>
            <div class="img-list" >
              <template v-for="(item, index) in logoFiles" >
                <img
                  :key="index"
                  :src="item"
                  alt=""
                  v-if="index >= logoFilesLength / 3 * 2">
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import sectionTitle from './../components/sectionTitle/sectionTitle'
import logoFiles from './logos'
import rowScroll from './directives/row-scroll'
import customElCollapse from './directives/customElCollapse'


export default {
  name: 'home',
  components: {
    sectionTitle
  },
  directives: {
    rowScroll,
    customElCollapse
  },
  data () {
    return {
      bannerData: [
        {
          url: require('./banner/banner1.png'),
          href: '',
          bgColor: '#354060'
        },
        {
          url: require('./banner/banner2.png'),
          href: '',
          bgColor: '#354060'
        },
        {
          url: require('./banner/banner3.png'),
          bgColor: '#354060'
        },
        {
          url: require('./banner/banner4.png'),
          bgColor: '#354060'
        }
      ],
      bannerTipsData: [
        {
          url: require('./img/banner-icon-1.png'),
          title: '行业深耕',
          content: '高新技术企业，专注人力资源18年'
        },
        {
          url: require('./img/banner-icon-2.png'),
          title: '业务覆盖',
          content: '互联网+人力资源服务，业务覆盖300多个城市'
        },
        {
          url: require('./img/banner-icon-3.png'),
          title: '集团规模',
          content: '人资行业龙头企业，全国63家线下分支机构'
        },
        {
          url: require('./img/banner-icon-4.png'),
          title: '服务企业',
          content: '服务大中型企业超1500家，服务员工超100万人'
        }
      ],
      product: [
        {
          name: '零工通',
          style: '',
          icon: 'page/homePage/views/index/home/img/bg-product-1.png',
          list: [
            {
              title: '定制化灵活用工服务方案设计',
              text: '可根据客户不同用工场景，通过数字化、智能化技术赋能企业实现无人化管理，帮助客户快速、高效、低成本的开展灵活用工业务。'
            },
            {
              title: '提供找人、管人、风险管控一体化服务',
              text: '平台通过庞大行业人才库，结合大数据NLP等智能算法，依托精细全面的用户标签体系和用户数据，为企业用工需求精准匹配C 端用户画像，确保全流程数字化风险管控。'
            },
            {
              title: '智能派接单、视频面试、人才评测、灵工保险',
              text: '根据智能人岗匹配算法精准推荐，结合视频面试、人才测评等特色功能，为企业提供省时、省力、省心的用工服务。'
            },
            {
              title: '全量用工数据沉淀，快速匹配优质人才',
              text: '平台通过C端用户历史接单任务、客户评价、佣金范围等多维度数据得出综合评分，在企业发布用工任务时快速匹配分数最高的优质人才。 '
            }
          ],
          path: '/flexibleEmployment'
        },
        {
          name: '众包平台',
          style: '',
          icon: 'page/homePage/views/index/home/img/bg-product-2.png',
          list: [
            {
              title: '支持灵工业务API对接',
              text: '支持有自研能力的客户通过API方式快速接入灵活用工业务模式，帮助客户快速开展灵活用工业务。'
            },
            {
              title: '智能分发任务，用工无忧',
              text: '大数据分析，AI智能分发任务，全程在线一体化，高效快捷'
            },
            {
              title: '业务全流程线上化，省时又省心',
              text: '发布任务、签约、发佣、开票、完税全流程在线操作完成，所有文件在线存证，永久有效，方便快捷。'
            },
            {
              title: '佣金实时结算，资金安全有保障',
              text: '所有资金进出账通过银行支付体系，平台全程不接触资金，支持7*24小时结算，佣金实时到账，支持微信支付宝发佣'
            }
          ],
          path: '/crowdSourcingServices'
        },
        {
          name: '服务商管理',
          style: '',
          icon: 'page/homePage/views/index/home/img/bg-product-3.png',
          list: [
            {
              title: '专为服务商设计的智能化办公工具',
              text: '基于灵活用工业务，专为服务商设计的贴合用工场景的业务流程管理工具。'
            },
            {
              title: '功能齐全，高效智能，操作简单',
              text: '提供钱包充值、佣金下发、申报缴纳税务、个税筹划，以及开票管理等流程管理功能，帮助服务商提高业务效率。'
            },
            {
              title: '支持分润管理、平台结算',
              text: '支持服务商与平台之间的佣金结算以及服务商的分润提现等功能。'
            },
            {
              title: '资金安全、秒级到账',
              text: '对接银行代付系统下发佣金，全程资金银行托管，安全可靠，实时到账。'
            }
          ],
          path: '/settlementServices'
        },
        {
          name: 'HRO SaaS',
          style: '',
          icon: 'page/homePage/views/index/home/img/bg-product-4.png',
          list: [
            {
              title: '自主研发的互联网SaaS智能薪酬工具',
              text: '基于灵活用工业务专为服务商设计的贴合用工场景的业务流程管理工具'
            },
            {
              title: '科技创新办公模式，让企业管理更轻松',
              text: '提供线上签约管理、员工管理、薪酬代发、缴纳个税等企业服务'
            },
            {
              title: '员工线上电子签约，一键查询',
              text: '支持员工线上签约，电子合同存证有效，随时随地一键查询，云储存无压力'
            },
            {
              title: '系统线上发薪，资金安全透明0失误',
              text: '全程资金银行托管安全可靠，系统计算数据再多也不怕。'
            }
          ],
          path: '/hroServices'
        },
        {
          name: '蓝领招聘',
          style: '',
          icon: 'page/homePage/views/index/home/img/bg-product-5.png',
          list: [
            {
              title: '看地图找工作、一键直面快速提高招聘效率',
              text: '创新研发特色招聘功能，吸引更多求职者前来找工作。'
            },
            {
              title: '商家版招聘小程序支持企业随时随地关注招聘动态',
              text: '提供企业版招聘网站及移动端招聘软件，实时了解招聘情况。'
            },
            {
              title: '智能简历推荐，自动标签、打分预筛选，快速匹配招聘需求',
              text: '求职者覆盖常见大类岗位，AI技术根据企业用工需求从海量人才库中精准推荐高质量简历。'
            },
            {
              title: '求职者信息真实有效，减少无效沟通',
              text: '平台求职者实名认证申请职位，信息真实有效，减少无效沟通。'
            }
          ],
          path: '/recruitService'
        }
      ],
      productIndex: 0,
      productActiveName: 0,
      advantage: [
        {
          name: '智能人岗匹配',
          icon: require('./img/icon-advantage-1.png'),
          text: '整合行业劳动力资源，结合大数据 NLP 等智能算法，依托精细全面的用户标签体系和用户数据，使用 AI 智能算法为客户匹配精准的 C 端用户画像。'
        },
        {
          name: '专业研发团队',
          icon: require('./img/icon-advantage-2.png'),
          text: '产研团队核心骨干主要来自腾讯、华为，多名算法专家有超5年机器学习算法经验，结合175万的海量人才数据库，目前人岗匹配率高达90%。'
        },
        {
          name: '数据安全合规',
          icon: require('./img/icon-advantage-3.png'),
          text: '通过大数据AI合规风控系统，全方位监控客户数据情况及时防范风险，所有资金往来银行监管，确保企业资金安全支付，7x24小时全面保障数据安全。'
        },
        {
          name: '服务专业高效',
          icon: require('./img/icon-advantage-4.png'),
          text: '税法、人资、客户成功、数字化、风控等多领域专家团队共同携手助力用工企业向数字化转型，快速高效的解决企业的招人管人难用工有风险的难题。'
        }
      ],
      logoFiles,
      logoFilesLength: logoFiles.length
    }
  },
  mounted() {
    this.setProductNavLine()
  },
  methods: {
    setProductNavLine() {
      const productItem = this.$refs.productItem || []
      productItem.map((item, index) => {
        this.product[index].style = `left: ${item.offsetLeft}px;width:${item.clientWidth}px`
      })
    },
    changeProductIndex(index) {
      this.productIndex = index
      this.productActiveName = 0
    }
  }
}
</script>
<style lang="less">
  .home {
    .home-banner {
      .el-carousel {
        min-width: 1200px;
        overflow: hidden;
      }
      .home-banner-content{
        .el-carousel__indicators{
          bottom: 100px;
        }
      }
      .el-carousel__indicator{
        padding: 0 15px;
        &.is-active .el-carousel__button{
          background-color: #FFFFFF;
          box-shadow: 0 0 0 5px rgba(255,255,255,.1);
        }
        .el-carousel__button{
          width: 12px;
          height: 12px;
          border: 2px solid #FFFFFF;
          opacity: 1;
          background-color: transparent;
          border-radius: 100%;
        }
      }
    }
  }
</style>

<style scoped lang="less">
  @import "home";
</style>
