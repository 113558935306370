function mouseoverFun (e) {
  if (e.target.className.includes('is-active')) {
    return
  }
  e.target.click()
}
let elCollapseItemHeader = null
export default {
  inserted(el) {
    elCollapseItemHeader = el.getElementsByClassName('el-collapse-item__header')[0]
    elCollapseItemHeader.addEventListener('mouseover', mouseoverFun)
  },
  unbind() {
    elCollapseItemHeader.removeEventListener('mouseover', mouseoverFun)
    elCollapseItemHeader = null
  }
}
// is-active
