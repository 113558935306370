let interval = null
let translateX = 0
export default {
  inserted(el, binding, vnode, oldVnode) {
    const logoContainerWidth = Math.ceil(binding.value / 3) * 240
    if (document.body.clientWidth > logoContainerWidth) {
      el.style.width = logoContainerWidth + 'px'
      return
    }
    el.style.width = logoContainerWidth * 2 + 'px'
    const imgLists = el.getElementsByClassName('img-list')
    ;[...imgLists].map(item => {
      const tem = document.createDocumentFragment()
      ;[...item.children].map(n => {
        tem.appendChild(n.cloneNode(true))
      })
      item.appendChild(tem)
    })
    interval = setInterval(() => {
      if (logoContainerWidth < translateX) {
        translateX = 0
      }
      el.style.transform = `translateX(${-translateX}px)`
      translateX += 1
    }, 10)
  },
  unbind: function () {
    clearInterval(interval)
  }
}

